* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

#root, body, html
{
  width:100%;
  height:100%;
}
