* {
    --color1: #fdfffcff;
    --color2: #235789ff;
    --color3: #c1292eff;
    --color4: #f1d302ff;
    --color5: #161925ff;
}

.App {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding:20px;
}

.button {
    padding: 20px;
    background-color: var(--color4);
    color: var(--color5);
    text-align: center;
    margin-bottom:20px;
}

.gameNumberInput
{
    background-color:var(--color5);
    color:var(--color4);
    font-size:30px;
    text-align: center;
    padding:10px;
}

.gameNumberInput-2
{
    background-color:var(--color3);
    color:var(--color5);
    font-size:30px;
    text-align: center;
    padding:10px;
}

.Lobby
{
	display:flex;
	flex-direction: column;
	grid-gap: 20px;
}

.alert
{
	font-style: italic;
	animation:fadeIn forwards 10s;
}

@keyframes fadeIn{
	0% {
		opacity:1;
	}

	100%{
		opacity:0;
	}
}